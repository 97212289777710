import { sendGAEvent } from '@next/third-parties/google'
import dayjs from 'dayjs'

import {
  POD_CUSTOMER_SUPPORT,
  POD_GROUP_TEST_DATE,
  TESTING_GROUP,
  GROWTH_PRICING_TIER,
  STARTER_PRICING_TIER,
  GDPR_RED_COUNTRIES,
  GDPR_GREEN_COUNTRIES,
  LEGACY_SELF_SERVICE_TIER,
  SUPPORT_EMAIL
} from '@constants/organisations'
import FeatureToggle from '@helpers/feature_toggle'

export const isGreenOrAmberGDPROrganisation = (organisation) => {
  if (!organisation) return false

  if (!FeatureToggle.isEnabled('leads_upsell'))
    return GDPR_GREEN_COUNTRIES.includes(organisation.brandHeadquartersCountry)

  return !GDPR_RED_COUNTRIES.includes(organisation.brandHeadquartersCountry)
}

export const isOrganisationLicenseOverFee = (organisation, fee) => {
  if (!organisation) return false

  return organisation.licenseNetTotal >= fee
}

export const isOrganisationSelfServe = (organisation) => {
  return (
    organisation?.pricingTier === GROWTH_PRICING_TIER ||
    organisation?.pricingTier === STARTER_PRICING_TIER ||
    organisation?.pricingTier === LEGACY_SELF_SERVICE_TIER
  )
}

export const isPodOnboardingGroup = (organisation, email) => {
  if (!organisation) return false

  if (TESTING_GROUP.includes(email)) return true

  return (
    organisation.customerSupportOwner === POD_CUSTOMER_SUPPORT &&
    dayjs
      .unix(organisation.createdAt / 1000)
      .isAfter(dayjs(POD_GROUP_TEST_DATE))
  )
}

export const sendOrganisationGAData = (organisation) => {
  sendGAEvent({
    organisation_id: organisation.id,
    pricing_tier: organisation.pricingTier
  })
}

export const organisationHasUkBrand = (organisation) => {
  return organisation?.brands?.nodes.some(
    (brandNode) => brandNode?.country?.code === 'UK'
  )
}

export const organisationHasUsBrand = (organisation) => {
  return organisation?.brands?.nodes.some(
    (brandNode) => brandNode?.country?.code === 'US'
  )
}

export const shouldShowWinsdayUpgrade = (id, organisation) => {
  if (
    id === 1 &&
    ((!organisationHasUkBrand(organisation) &&
      !organisationHasUsBrand(organisation)) ||
      !isGreenOrAmberGDPROrganisation(organisation))
  )
    return false

  return true
}

export const getPionStaffEmail = (name) => {
  if (!name || name === 'Customer Support') return SUPPORT_EMAIL

  const formattedName = name.replace(' ', '.').toLowerCase()
  return `${formattedName}@thebeansgroup.com`
}
